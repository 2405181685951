// import i18next from 'i18next'

export const setVerifyInputFile = (fileInput) => (dispatch) => {
    dispatch({
        type: 'SET_VERIFY_INPUT_FILE',
        payload: fileInput
    })
}

export const setVerifyDSInputFile = (fileDSInput) => (dispatch) => {
    dispatch({
        type: 'SET_VERIFY_DS_INPUT_FILE',
        payload: fileDSInput
    })
}

export const setFileWithDSForUpgrade = (fileInput) => (dispatch) => {
    dispatch({
        type: 'SET_FILE_WITH_DS_FOR_UPGARDE',
        payload: fileInput
    })
}

export const setBaseFileForUpgrade = (fileInput) => (dispatch) => {
    dispatch({
        type: 'SET_BASE_FILE_FOR_UPGRADE',
        payload: fileInput
    })
}

export const setSignatureDifferenceType = (type) => (dispatch) => {
    dispatch({
        type: 'SET_SIGNATURE_DIFFERENCE_TYPE',
        payload: type
    })
}

export const clearSignatureDifferenceType = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_SIGNATURE_DIFFERENCE_TYPE',
        payload: null
    })
}

export const clearFileWithDSForUpgrade = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_FILE_WITH_DS_FOR_UPGRADE',
        payload: null
    })
}

export const clearBaseFileForUpgrade = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_BASE_FILE_FOR_UPGRADE',
        payload: null
    })
}

export const setModifiedDSData = (data) => (dispatch) => {
    dispatch({
        type: 'SET_MODIFIED_DS_DATA',
        payload: data
    })
}

export const clearModifiedDSData = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_MODIFIED_DS_DATA',
        payload: null
    })
}

export const setSecret = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SECRET',
        payload: value
    })
}

export const clearSecret = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_SECRET',
        payload: null
    })
}

export const setVPNToken = (token) => (dispatch) => {
    dispatch({
        type: 'SET_VPN_TOKEN',
        payload: token
    })
}

export const clearVPNToken = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_VPN_TOKEN',
        payload: null
    })
}

export const setVPNTokenData = (data) => (dispatch) => {
    dispatch({
        type: 'SET_VPN_TOKEN_DATA',
        payload: data
    })
}

export const clearVPNTokenData = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_VPN_TOKEN_DATA',
        payload: null
    })
}

export const setProfileFields = (fields) => (dispatch) => {
    dispatch({
        type: 'SET_PROFILE_FIELDS',
        payload: fields
    })
}

export const clearProfileFields = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_PROFILE_FIELDS',
        payload: null
    })
}

export const setResultKey = (key) => (dispatch) => {
    dispatch({
        type: 'SET_RESULT_KEY',
        payload: key
    })
}

export const clearResultKey = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_RESULT_KEY',
        payload: null
    })
}

export const setErrorResponse = (data) => (dispatch) => {
    dispatch({
        type: 'SET_ERROR_RESPONSE',
        payload: data
    })
}

export const clearErrorRespons = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_ERROR_RESPONSE',
        payload: null
    })
}

export const setMsspId = (value) => (dispatch) => {
    dispatch({
        type: 'SET_MOBILE_DS_MSSPID',
        payload: value
    })
}

export const setIsMobileDevice = (value) => (dispatch) => {
    dispatch({
        type: 'SET_IS_MOBILE_DEVICE',
        payload: value
    })
}

export const setMobileDeviceMode = (value) => (dispatch) => {
    dispatch({
        type: 'SET_MOBILE_DEVICE_MODE',
        payload: value
    })
}

export const setVerifyDSOptionsExpand = (value) => (dispatch) => {
    dispatch({
        type: 'SET_VERIFY_DS_OPTIONS_EXPAND',
        payload: !value
    })
}

export const setVerifyDSExpand = (value) => (dispatch) => {
    dispatch({
        type: 'SET_VERIFY_DS_EXPAND',
        payload: !value
    })
}

export const setVerifyDSTextExpand = (value) => (dispatch) => {
    dispatch({
        type: 'SET_VERIFY_DS_TEXT_EXPAND',
        payload: !value
    })
}

export const setCreateDSOptionsExpand = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CREATE_DS_OPTIONS_EXPAND',
        payload: !value
    })
}

export const setCreateDSExpand = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CREATE_DS_EXPAND',
        payload: !value
    })
}

export const setCreateDSTextExpand = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CREATE_DS_TEXT_EXPAND',
        payload: !value
    })
}

export const setEncryptOptionsExpand = (value) => (dispatch) => {
    dispatch({
        type: 'SET_ENCRYPT_OPTIONS_EXPAND',
        payload: !value
    })
}

export const setEncryptExpand = (value) => (dispatch) => {
    dispatch({
        type: 'SET_ENCRYPT_EXPAND',
        payload: !value
    })
}

export const setEncryptTextExpand = (value) => (dispatch) => {
    dispatch({
        type: 'SET_ENCRYPT_TEXT_EXPAND',
        payload: !value
    })
}

export const setEncryptCertExpand = (value) => (dispatch) => {
    dispatch({
        type: 'SET_ENCRYPT_CERT_EXPAND',
        payload: !value
    })
}

export const setDecryptExpand = (value) => (dispatch) => {
    dispatch({
        type: 'SET_DECRYP_EXPAND',
        payload: !value
    })
}

export const setDecryptTextExpand = (value) => (dispatch) => {
    dispatch({
        type: 'SET_DECRYP_TEXT_EXPAND',
        payload: !value
    })
}

export const setActionsExpand = (value) => (dispatch) => {
    dispatch({
        type: 'SET_ACTIONS_EXPAND',
        payload: !value
    })
}

export const setCreateDSAddToExistExpand = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CREATE_DS_ADD_TO_EXIST_EXPAND',
        payload: !value
    })
}

export const setVerifyInputText = (text) => (dispatch) => {
    dispatch({
        type: 'SET_VERIFY_INPUT_TEXT',
        payload: text
    })
}

export const clearVerifyInputText = (text) => (dispatch) => {
    dispatch({
        type: 'CLEAR_VERIFY_INPUT_TEXT',
        payload: text
    })
}

export const setDSActive = (state) => (dispatch) => {
    dispatch({
        type: 'SET_DS_ACTIVE',
        payload: state
    })
}

export const setFileSignedData = (file) => (dispatch) => {
    dispatch({
        type: 'SET_FILE_SIGNED_DATA',
        payload: file
    })
}

export const setTextSignedData = (text) => (dispatch) => {
    dispatch({
        type: 'SET_SIGNED_TEXT_DATA',
        payload: text
    })
}

export const setOwnerOrganization = (organization) => (dispatch) => {
    dispatch({
        type: 'SET_OWNERS_ORGANIZATION',
        payload: organization
    })
}

export const clearOwnerOrganization = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_OWNERS_ORGANIZATION',
        payload: null
    })
}

export const setMobileSignatureCert = (cert) => (dispatch) => {
    dispatch({
        type: 'SET_MOBILE_SIGNATURE_CERT',
        payload: cert
    })
}

export const clearTextSignedData = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_SIGNED_TEXT_DATA',
        payload: null
    })
}

export const setInputFileForEncrypt = (file) => (dispatch) => {
    dispatch({
        type: 'SET_INPUT_FILE_FOR_ENCRYPT',
        payload: file
    })
}

export const setUccConnectionStatus = (status) => (dispatch) => {
    dispatch({
        type: 'SET_UCC_CONNECTION_STATUS',
        payload: status
    })
}

export const setCloudConnectionStatus = (status) => (dispatch) => {
    dispatch({
        type: 'SET_CLOUD_CONNECTION_STATUS',
        payload: status
    })
}

export const setSelectedKeyContainer = (caId) => (dispatch) => {
    dispatch({
        type: 'SET_SELECTED_KEY_CONTAINER',
        payload: caId
    })
}

export const setMobileDSMode = (value) => (dispatch) => {
    dispatch({
        type: 'SET_MOBILE_DS_MODE',
        payload: value
    })
}

export const setPhoneNumber = (phoneNumber) => (dispatch) => {
    dispatch({
        type: 'SET_PHONE_NUMBER',
        payload: phoneNumber
    })
}

export const clearPhoneNumber = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_PHONE_NUMBER',
        payload: ""
    })
}

export const setFullPhoneNumber = (phoneNumber) => (dispatch) => {
    dispatch({
        type: 'SET_FULL_PHONE_NUMBER',
        payload: phoneNumber
    })
}

export const clearFullPhoneNumber = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_FULL_PHONE_NUMBER',
        payload: ""
    })
}

export const setPositionId = (positionId) => (dispatch) => {
    dispatch({
        type: 'SET_POSITION_ID',
        payload: positionId
    })
}

export const clearPositionId = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_POSITION_ID',
        payload: ""
    })
}

export const setServiceId = (serviceId) => (dispatch) => {
    dispatch({
        type: 'SET_SERVICE_ID',
        payload: serviceId
    })
}

export const clearServiceId = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_SERVICE_ID',
        payload: ""
    })
}

export const setMobileDSProviders = (providers) => (dispatch) => {
    dispatch({
        type: 'SET_MOBILE_DS_PROVIDERS',
        payload: providers
    })
}

export const clearMobileDSProviders = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_MOBILE_DS_PROVIDERS',
        payload: null
    })
}

export const clearSelectedKeyContainer = (caId) => (dispatch) => {
    dispatch({
        type: 'CLEAR_SELECTED_KEY_CONTAINER',
        payload: caId
    })
}

export const clearFileSignedData = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_FILE_SIGNED_DATA',
        payload: null
    })
}

export const clearInputFileForEncrypt = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_INPUT_FILE_FOR_ENCRYPT',
        payload: null
    })
}

export const setKeyContainer = (container) => (dispatch) => {
    dispatch({
        type: 'SET_KEY_CONTAINER',
        payload: container
    })
}

export const setKeyContainerPath = (container) => (dispatch) => {
    dispatch({
        type: 'SET_KEY_CONTAINER_PATH',
        payload: container
    })
}

export const setKeysProfiles = (profiles) => (dispatch) => {
    dispatch({
        type: 'SET_KEYS_PROFILES',
        payload: profiles
    })
}

export const setProfile = (profile) => (dispatch) => {
    dispatch({
        type: 'SET_PROFILE',
        payload: profile
    })
}

export const clearKeyContainer = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_KEY_CONTAINER',
        payload: null
    })
}

export const setKeyContainerPass = (pass) => (dispatch) => {
    dispatch({
        type: 'SET_KEY_CONTAINER_PASS',
        payload: pass
    })
}

export const clearKeyContainerPass = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_KEY_CONTAINER_PASS',
        payload: null
    })
}

export const setCertificateInputFile = (crt) => (dispatch) => {
    dispatch({
        type: 'SET_CERTIFICATE_INPUT_FILE',
        payload: crt
    })
}

export const clearCertificateInputFile = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_CERTIFICATE_INPUT_FILE',
        payload: null
    })
}

export const setInputDescription = (description) => (dispatch) => {
    dispatch({
        type: 'SET_DS_INPUT_DESCRIPTION',
        payload: description
    })
}

export const setInputFileForDS = (fileInput) => (dispatch) => {
    dispatch({
        type: 'SET_INPUT_FILE_FOR_DS',
        payload: fileInput
    })
}

export const setFilesWithDS = (fileInput) => (dispatch) => {
    dispatch({
        type: 'SET_FILES_WITH_DS',
        payload: fileInput
    })
}

export const setEncryptedFiles = (fileInput) => (dispatch) => {
    dispatch({
        type: 'SET_ENCRYPTED_FILES',
        payload: fileInput
    })
}

export const setFilesForDS = (fileList) => (dispatch) => {
    dispatch({
        type: 'SET_FILES_FOR_DS',
        payload: fileList
    })
}

export const setFilesForEncrypt = (fileList) => (dispatch) => {
    dispatch({
        type: 'SET_FILES_FOR_ENCRYPT',
        payload: fileList
    })
}

export const clearFilesForEncrypt = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_FILES_FOR_ENCRYPT_LIST',
        payload: null
    })
}

export const clearEncryptedFiles = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_ENCRYPTED_FILES',
        payload: null
    })
}

export const setBase64DSForUpgrade = (text) => (dispatch) => {
    dispatch({
        type: 'SET_BASE_64_DS_FOR_UPGRADE',
        payload: text
    })
}

export const clearBase64DSForUpgrade = (text) => (dispatch) => {
    dispatch({
        type: 'CLEAR_BASE_64_DS_FOR_UPGRADE',
        payload: text
    })
}

export const setBaseTextForUpgrade = (text) => (dispatch) => {
    dispatch({
        type: 'SET_BASE_TEXT_FOR_UPGRADE',
        payload: text
    })
}

export const clearBaseTextForUpgrade = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_BASE_TEXT_FOR_UPGRADE',
        payload: ""
    })
}

export const setInputTextForUpgradeUint8Arr = (arr) => (dispatch) => {
    dispatch({
        type: 'SET_INPUT_TEXT_FOR_UPGRADE_UINT8_ARR',
        payload: arr
    })
}

export const clearInputTextForUpgradeUint8Arr = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_INPUT_TEXT_FOR_UPGRADE_UINT8_ARR',
        payload: ""
    })
}

export const setInputTextDSForVerify = (text) => (dispatch) => {
    dispatch({
        type: 'SET_INPUT_TEXT_DS_FOR_VERIFY',
        payload: text
    })
}

export const clearInputTextDSForVerify = (text) => (dispatch) => {
    dispatch({
        type: 'CLEAR_INPUT_TEXT_DS_FOR_VERIFY',
        payload: ""
    })
}

export const setInputTextForDS = (text) => (dispatch) => {
    dispatch({
        type: 'SET_INPUT_TEXT_FOR_DS',
        payload: text
    })
}

export const setInputTextForEncrypt = (text) => (dispatch) => {
    dispatch({
        type: 'SET_INPUT_TEXT_FOR_ENCRYPT',
        payload: text
    })
}

export const setInputTextForDecrypt = (text) => (dispatch) => {
    dispatch({
        type: 'SET_INPUT_TEXT_FOR_DECRYPT',
        payload: text
    })
}

export const setInputTextForDecryptUintArr = (arr) => (dispatch) => {
    dispatch({
        type: 'SET_INPUT_TEXT_FOR_DECRYPT_UINT8_ARR',
        payload: arr
    })
}

export const setDecryptText = (text) => (dispatch) => {
    dispatch({
        type: 'SET_DECRYPT_TEXT',
        payload: text
    })
}

export const clearDecryptText = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_DECRYPT_TEXT',
        payload: ""
    })
}

export const setInputTextForEncryptUintArr = (arr) => (dispatch) => {
    dispatch({
        type: 'SET_INPUT_TEXT_FOR_ENCRYPT_UINT8_ARR',
        payload: arr
    })
}

export const setInputTextForDSUintArr = (arr) => (dispatch) => {
    dispatch({
        type: 'SET_INPUT_TEXT_FOR_DS_UINT8_ARR',
        payload: arr
    })
}

export const clearInputTextForDSUintArr = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_INPUT_TEXT_FOR_DS_UINT8_ARR',
        payload: null
    })
}

export const clearInputTextForDecryptUintArr = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_INPUT_TEXT_FOR_DECRYPT_UINT8_ARR',
        payload: null
    })
}

export const clearInputTextForDecrypt = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_INPUT_TEXT_FOR_DECRYPT',
        payload: ""
    })
}

export const clearInputTextForEncryptUintArr = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_INPUT_TEXT_FOR_ENCRYPT_UINT8_ARR',
        payload: null
    })
}

export const setInputTextForDSDescription = (text) => (dispatch) => {
    dispatch({
        type: 'SET_DS_INPUT_TEXT_DESCRIPTION',
        payload: text
    })
}

export const setInputTextForVerifyDS = (text) => (dispatch) => {
    dispatch({
        type: 'SET_INPUT_TEXT_FOR_VERIFY_DS',
        payload: text
    })
}

export const setDSData = (blob) => (dispatch) => {
    dispatch({
        type: 'SET_DS_DATA',
        payload: blob
    })
}

export const setDSDataBase64 = (b64) => (dispatch) => {
    dispatch({
        type: 'SET_DS_DATA_BASE_64',
        payload: b64
    })
}

export const setTextDSDataBase64 = (b64) => (dispatch) => {
    dispatch({
        type: 'SET_TEXT_DS_DATA_BASE_64',
        payload: b64
    })
}

export const clearTextDSDataBase64 = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_TEXT_DS_DATA_BASE_64',
        payload: ""
    })
}

export const setUpgradedDSInBase64 = (b64) => (dispatch) => {
    dispatch({
        type: 'SET_UPGRADED_DS_IN_BASE_64',
        payload: b64
    })
}

export const clearUpgradedDSInBase64 = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_UPGRADED_DS_IN_BASE_64',
        payload: ""
    })
}

export const setEncryptDataBase64 = (b64) => (dispatch) => {
    dispatch({
        type: 'SET_ENCRYPT_DATA_BASE_64',
        payload: b64
    })
}

export const clearDSDataBase64 = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_DS_DATA_BASE_64',
        payload: ""
    })
}

export const clearEncryptDataBase64 = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_ENCRYPT_DATA_BASE_64',
        payload: ""
    })
}

export const clearDSInputTextDescription = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_DS_INPUT_TEXT_DESCRIPTION',
        payload: ""
    })
}

export const setInputFileForDecrypt = (file) => (dispatch) => {
    dispatch({
        type: 'SET_INPUT_FILE_FOR_DECRYPT',
        payload: file
    })
}

export const clearInputFileForDecrypt = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_INPUT_FILE_FOR_DECRYPT',
        payload: null
    })
}

export const setDecryptedData = (blob) => (dispatch) => {
    dispatch({
        type: 'SET_DECRYPTED_DATA',
        payload: blob
    })
}

export const clearDecryptedData = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_DECRYPTED_DATA',
        payload: null
    })
}

export const setURLs = (urls) => (dispatch) => {
    dispatch({
        type: 'SET_URLS',
        payload: urls.serviceURLs
    })
}

export const setVersion = (data) => (dispatch) => {
    dispatch({
        type: 'SET_VERSION',
        payload: data
    })
}

export const setEncryptedData = (blob) => (dispatch) => {
    dispatch({
        type: 'SET_ENCRYPTED_DATA',
        payload: blob
    })
}

export const clearEncryptedData = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_ENCRYPTED_DATA',
        payload: null
    })
}

export const clearInputFileForDS = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_INPUT_FILE_FOR_DS',
        payload: null
    })
}

export const clearFileList = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_FILE_LIST',
        payload: null
    })
}

export const clearFileListWithDS = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_FILE_LIST_WITH_DS',
        payload: null
    })
}

export const clearInputTextForDS = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_INPUT_TEXT_FOR_DS',
        payload: ""
    })
}

export const clearInputTextForEncrypt = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_INPUT_TEXT_FOR_ENCRYPT',
        payload: ""
    })
}

export const clearDSData = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_DS_DATA',
        payload: null
    })
}

export const clearInputDescription = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_DS_INPUT_DESCRIPTION',
        payload: null
    })
}

export const clearVerifyInputFile = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_VERIFY_INPUT_FILE',
        payload: null
    })
}

export const clearVerifyDSInputFile = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_VERIFY_DS_INPUT_FILE',
        payload: null
    })
}

export const setSelectedContainer = (container) => (dispatch) => {
    dispatch({
        type: 'SELECTED_CONTAINER',
        payload: container
    })
}

export const setSelectedContainerData = (containerData, pass) => (dispatch) => {
    dispatch({
        type: 'SELECTED_CONTAINER_DATA',
        payload: {containerData, pass}
    })
}

export const expandDS = (value) => (dispatch) => {
    dispatch({
        type: 'EXPAND_DS_TYPE',
        payload: !value
    })
}

export const expandEncType = (value) => (dispatch) => {
    dispatch({
        type: 'EXPAND_ENC_TYPE',
        payload: !value
    })
}

export const setCertificateInfo = (info) => (dispatch) => {
    dispatch({
        type: 'CERTIFICATE_INFO',
        payload: info
    })
}

export const setCertificateInfoKeyAgreement = (info) => (dispatch) => {
    dispatch({
        type: 'CERTIFICATE_INFO_KEY_AGREEMENT',
        payload: info
    })
}

export const clearCertificateInfo = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_CERTIFICATE_INFO',
        payload: null
    })
}

export const clearCertificateInfoKeyAgreement = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_CERTIFICATE_INFO_KEY_AGREEMENT',
        payload: null
    })
}

export const setCertType = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CERT_TYPE',
        payload: value
    })
}

export const setSelectedTab = (tab) => (dispatch) => {
    dispatch({
        type: 'SET_SELECTED_TAB',
        payload: tab
    })
}

export const expandCreateDS = (value) => (dispatch) => {
    dispatch({
        type: 'EXPAND_CREATE_DS_TYPE',
        payload: !value
    })
}

export const setPassiveTokenPath = (arr) => (dispatch) => {
    dispatch({
        type: 'SET_PASSIVE_TOKEN_PATH',
        payload: arr
    })
}

export const setActiveTokenPath = (arr) => (dispatch) => {
    dispatch({
        type: 'SET_ACTIVE_TOKEN_PATH',
        payload: arr
    })
}

export const setSelectedMode = (mode) => (dispatch) => {
    dispatch({
        type: 'SET_SELECTED_MODE',
        payload: mode
    })
}

export const setActiveToken = (path) => (dispatch) => {
    dispatch({
        type: 'SET_ACTIVE_TOKEN',
        payload: path
    })
}

export const setPassiveToken = (path) => (dispatch) => {
    dispatch({
        type: 'SET_PASSIVE_TOKEN',
        payload: path
    })
}

export const setTokenPathHSM = (path) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_PATH_HSM',
        payload: path
    })
}

export const setDepositSignKeysInfo = (keysInfo) => (dispatch) => {
    dispatch({
        type: 'SET_DEPOSITSIGN_KEYS_INFO',
        payload: keysInfo
    })
}

export const clearDepositSignKeysInfo = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_DEPOSITSIGN_KEYS_INFO',
        payload: null
    })
}

export const setDepositSignSelectedKey = (key) => (dispatch) => {
    dispatch({
        type: 'SET_DEPOSITSIGN_SELECTED_KEY',
        payload: key
    })
}

export const clearDepositSignSelectedKey = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_DEPOSITSIGN_SELECTED_KEY',
        payload: null
    })
}

export const setUseUccConnection = (value) => (dispatch) => {
    dispatch({
        type: 'SET_USE_UCC_CONNECTION',
        payload: value
    })
}

export const setUseTwoFactorCode = (value) => (dispatch) => {
    dispatch({
        type: 'SET_USE_TWO_FACTOR_CODE',
        payload: value
    })
}

export const setTwoFactorCode = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TWO_FACTOR_CODE',
        payload: value
    })
}

export const setShowPinInput = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_PIN_INPUT',
        payload: value
    })
}

export const setTmpPin = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TMP_PIN',
        payload: value
    })
}

export const setTmpTwoFactorCode = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TMP_TWO_FACTOR_CODE',
        payload: value
    })
}

export const setDepositSignUserName = (userName) => (dispatch) => {
    dispatch({
        type: 'SET_DEPOSITSIGN_USER_NAME',
        payload: userName
    })
}

export const clearDepositSignUserName = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_DEPOSITSIGN_USER_NAME',
        payload: null
    })
}

export const setVtcoUserName = (userName) => (dispatch) => {
    dispatch({
        type: 'SET_VTCO_USER_NAME',
        payload: userName
    })
}

export const clearVtcoUserName = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_VTCO_USER_NAME',
        payload: null
    })
}

export const clearTokenPathHSM = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_TOKEN_PATH_HSM',
        payload: null
    })
}

export const expandTSVerify = (value) => (dispatch) => {
    dispatch({
        type: 'EXPAND_TS_VERIFY',
        payload: !value
    })
}

export const expandCreateDSTSVerify = (value) => (dispatch) => {
    dispatch({
        type: 'EXPAND_CREATE_DS_TS_VERIFY',
        payload: !value
    })
}

export const expandCreateDSCadesType = (value) => (dispatch) => {
    dispatch({
        type: 'EXPAND_CREATE_DS_CADES_TYPE',
        payload: !value
    })
}

export const expandDataVerifyTs = (value) => (dispatch) => {
    dispatch({
        type: 'EXPAND_DATA_VERIFY_TS',
        payload: !value
    })
}

export const setSignatureType = (type) => (dispatch) => {
    dispatch({
        type: 'SET_SIGNATURE_TYPE',
        payload: type
    })
}

export const setInit = (value) => (dispatch) => {
    dispatch({
        type: 'SET_INIT',
        payload: value
    })
}

export const setCadesType = (type) => (dispatch) => {
    dispatch({
        type: 'SET_CADES_TYPE',
        payload: type
    })
}

export const setDSSignatureType = (type) => (dispatch) => {
    dispatch({
        type: 'SET_DS_SIGNATURE_TYPE',
        payload: type
    })
}

export const setDSEncodingType = (type) => (dispatch) => {
    dispatch({
        type: 'SET_DS_ENCODING_TYPE',
        payload: type
    })
}

export const setEncryptEncodingType = (type) => (dispatch) => {
    dispatch({
        type: 'SET_ENCRYPT_ENCODING_TYPE',
        payload: type
    })
}

export const setDecryptEncodingType = (type) => (dispatch) => {
    dispatch({
        type: 'SET_DECRYPT_ENCODING_TYPE',
        payload: type
    })
}

export const setVerifyDSEncodingType = (type) => (dispatch) => {
    dispatch({
        type: 'SET_VERIFY_DS_ENCODING_TYPE',
        payload: type
    })
}

export const setVerifyResultData = (data, div) => (dispatch) => {
    dispatch({
        type: 'SET_VERIFY_RESULT_DATA',
        payload: {data, div}
    })
}

export const setVerifyResultText = (data, div) => (dispatch) => {
    dispatch({
        type: 'SET_VERIFY_RESULT_TEXT',
        payload: {data, div}
    })
}

export const clearVerifyResultText = (data, div) => (dispatch) => {
    dispatch({
        type: 'CLEAR_VERIFY_RESULT_TEXT',
        payload: null
    })
}

export const setPrevisionDsFile = (file) => (dispatch) => {
    dispatch({
        type: 'SET_PREVISION_DS_DATA',
        payload: file
    })
}

export const setPrevisionDsText = (text) => (dispatch) => {
    dispatch({
        type: 'SET_PREVISION_DS_TEXT_DATA',
        payload: text
    })
}

export const clearVerifyResultData = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_VERIFY_RESULT_DATA',
        payload: null
    })
}

export const clearPrevisionDsFile = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_PREVISION_DS_DATA',
        payload: null
    })
}

export const clearPrevisionDsText = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_PREVISION_DS__TEXT_DATA',
        payload: null
    })
}

export const setAddDsToExist = (value) => (dispatch) => {
    dispatch({
        type: 'SET_ADD_DS_TO_EXIST',
        payload: value
    })
}

export const setDuplicateSign = (value) => (dispatch) => {
    dispatch({
        type: 'SET_DUPLICATE_SIGN',
        payload: value
    })
}

export const setShowUpgradeCard = (value) => (dispatch) => {
    dispatch({
        type: 'SHOW_UPGARADE_CARD',
        payload: value
    })
}

export const setTsCheck = (type) => (dispatch) => {
    dispatch({
        type: 'SET_TS_CHECK',
        payload: type
    })
}

export const addDataTs = (checked, value) => (dispatch) => {
    dispatch({
        type: 'ADD_DATA_TS',
        payload: {checked, value}
    })
}

export const addDsTs = (checked, value) => (dispatch) => {
    dispatch({
        type: 'ADD_DS_TS',
        payload: {checked, value}
    })
}

export const dsTsCheck = (value) => (dispatch) => {
    dispatch({
        type: 'DS_TS_CHECK',
        payload: value
    })
}

export const setTsVerifyType = (type) => (dispatch) => {
    dispatch({
        type: 'SET_TS_VERIFY_TYPE',
        payload: type
    })
}

export const setListCSK = (csk) => (dispatch) => {
    dispatch({
        type: 'SET_LIST_CSK',
        payload: csk
    })
}

export const setCertificateInfoKeyAgreementBase64 = (base64) => (dispatch) => {
    dispatch({
        type: 'SET_CERT_INFO_KEY_AGREEMENT_BASE64',
        payload: base64
    })
}

export const setCAId = (id) => (dispatch) => {
    dispatch({
        type: 'SET_CA_ID',
        payload: id
    })
}

export const setIsActive = (isActive) => (dispatch) => {
    dispatch({
        type: 'SET_IS_ACTIVE',
        payload: isActive
    })
}

export const setCleanUpAll = (all) => (dispatch) => {
    dispatch({
        type: 'SET_CLEAN_UP_ALL',
        payload: all
    })
}

export const setVerifyResultForQR = (data) => (dispatch) => {
    dispatch({
        type: 'SET_VERIFY_RESULT_FOR_QR',
        payload: data
    })
}

export const clearVerifyResultForQR = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_VERIFY_RESULT_FOR_QR',
        payload: null
    })
}

export const setQRList = (data) => (dispatch) => {
    dispatch({
        type: 'SET_QR_LIST',
        payload: data
    })
}

export const clearQRList = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_QR_LIST',
        payload: null
    })
}

export const setIsLoading = (value) => (dispatch) => {
    dispatch({
        type: 'SET_IS_LOADING',
        payload: value
    })
}

export const setVerifyResultsForAddToExist = (data) => (dispatch) => {
    dispatch({
        type: 'SET_VERIFY_RESULTS_FOR_ADD_TO_EXIST',
        payload: data
    })
}

export const setQRVerifyResultsForAddToExist = (data) => (dispatch) => {
    dispatch({
        type: 'SET_QR_VERIFY_RESULTS_FOR_ADD_TO_EXIST',
        payload: data
    })
}

export const clearVerifyResultsForAddToExist = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_VERIFY_RESULTS_FOR_ADD_TO_EXIST',
        payload: null
    })
}

export const clearQRVerifyResultsForAddToExist = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_QR_VERIFY_RESULTS_FOR_ADD_TO_EXIST',
        payload: null
    })
}

export const setGenerateQR = (value) => (dispatch) => {
    dispatch({
        type: 'SET_GENERATE_QR',
        payload: value
    })
}

export const setCleanUpForm = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CLEAN_UP_FORM',
        payload: value
    })
}

export const setGenerateQRDS = (value) => (dispatch) => {
    dispatch({
        type: 'SET_GENERATE_QR_DS',
        payload: value
    })
}

export const setShowKeyGenTab = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_KEY_GEN_TAB',
        payload: value
    })
}

export const setBodyIsMounted = (value) => (dispatch) => {
    dispatch({
        type: 'SET_BODY_IS_MOUNTED',
        payload: value
    })
}

export const setAllFieldsFiled = (value) => (dispatch) => {
    dispatch({
        type: 'SET_ALL_FIELDS_FILED',
        payload: value
    })
}

export const setGlobalProcessing = (value) => (dispatch) => {
    dispatch({
        type: 'SET_GLOBAL_PROCESSING',
        payload: value
    })
}

export const setVtcoKeysInfo = (value) => (dispatch) => {
    dispatch({
        type: 'SET_VTCO_KEYS_INFO',
        payload: value
    })
}

export const clearVtcoKeysInfo = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_VTCO_KEYS_INFO',
        payload: null
    })
}

export const setVtcoSelectedKey = (value) => (dispatch) => {
    dispatch({
        type: 'SET_VTCO_SELECTED_KEY',
        payload: value
    })
}

export const clearVtcoSelectedKey = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_VTCO_SELECTED_KEY',
        payload: null
    })
}

export const setTwoFactorEnable = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TWO_FACTOR_ENABLE',
        payload: value
    })
}


export const setVtcoTokenBody = (value) => (dispatch) => {
    dispatch({
        type: 'SET_VTCO_TOKEN_BODY',
        payload: value
    })
}

export const setProtectedParams = (value) => (dispatch) => {
    dispatch({
        type: 'SET_PROTECTED_PARAMS',
        payload: value
    })
}

export const clearProtectedParams = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_PROTECTED_PARAMS',
        payload: null
    })
}

export const setIsVtco = (value) => (dispatch) => {
    dispatch({
        type: 'SET_IS_VTCO',
        payload: value
    })
}

export const setVtcoPin = (value) => (dispatch) => {
    dispatch({
        type: 'SET_VTCO_PIN',
        payload: value
    })
}

export const setVtcoTwoFactorCode = (value) => (dispatch) => {
    dispatch({
        type: 'SET_VTCO_TWO_FACTOR_CODE',
        payload: value
    })
}

export const setVtcoUrl = (value) => (dispatch) => {
    dispatch({
        type: 'SET_VTCO_URL',
        payload: value
    })
}

export const setSignatureCertificate = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SIGNATURE_CERTIFICATE',
        payload: value
    })
}

export const setKeyAgreementCertificate = (value) => (dispatch) => {
    dispatch({
        type: 'SET_KEY_AGREEMENT_CERTIFICATE',
        payload: value
    })
}

export const setTokenInfo = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_INFO',
        payload: value
    })
}

export const setIsLoggedIn = (value) => (dispatch) => {
    dispatch({
        type: 'SET_IS_LOGGED_IN',
        payload: value
    })
}

export const setAuthLogin = (value) => (dispatch) => {
    dispatch({
        type: 'SET_AUTH_LOGIN',
        payload: value
    })
}

export const setAuthPassword = (value) => (dispatch) => {
    dispatch({
        type: 'SET_AUTH_PASSWORD',
        payload: value
    })
}

export const setToken = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN',
        payload: value
    })
}

export const setExpireTime = (value) => (dispatch) => {
    dispatch({
        type: 'SET_EXPIRE_TIME',
        payload: value
    })
}

export const setTwoFactorEnabledAuth = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TWO_FACTOR_ENABLED_AUTH',
        payload: value
    })
}

export const clearTwoFactorEnabledAuth = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_TWO_FACTOR_ENABLED_AUTH',
        payload: null
    })
}

export const setTwoFactorCodeAuth = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TWO_FACTOR_CODE_AUTH',
        payload: value
    })
}

export const clearTwoFactorCodeAuth = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_TWO_FACTOR_CODE_AUTH',
        payload: null
    })
}

export const setIs2fEnabled = (value) => (dispatch) => {
    dispatch({
        type: 'SET_IS_2F_ENABLED',
        payload: value
    })
}

export const setTimeDelta = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TIME_DELTA',
        payload: value
    })
}

export const setPublicKeyCurrentUtcDateTime = (data) => (dispatch) => {
    dispatch({
        type: 'SET_PUBLIC_KEY_CURRENT_UTC_DATE_TIME',
        payload: data
    })
}

export const showVisualImpairmentView = (value) => (dispatch) => {
    dispatch({
        type: 'SHOW_VISUAL_IMPAIRMENT_VIEW',
        payload: value
    })
}

export const setContrast = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CONTRAST',
        payload: value
    })
}

export const setFontSize = (value) => (dispatch) => {
    dispatch({
        type: 'SET_FONTSIZE',
        payload: value
    })
}

export const setShowPreviewPopup = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_PREVIEW_POPUP',
        payload: value
    })
}

export const setShowPreviewPopupVerify = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_PREVIEW_POPUP_VERIFY',
        payload: value
    })
}

export const expandCreateDSContainerType = (value) => (dispatch) => {
    dispatch({
        type: 'EXPAND_CREATE_DS_CONTAINER_TYPE',
        payload: !value
    })
}

export const setXadesType = (type) => (dispatch) => {
    dispatch({
        type: 'SET_XADES_TYPE',
        payload: type
    })
}

export const setAsicContainerForm = (type) => (dispatch) => {
    dispatch({
        type: 'SET_ASIC_CONTAINER_FORM',
        payload: type
    })
}

export const setUosAgentNativeBuilds = (value) => (dispatch) => {
    dispatch({
        type: 'SET_UOS_AGENT_NATIVE_BUILDS',
        payload: value
    })
}

export const setShowErrorPopup = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_ERROR_POPUP',
        payload: value
    })
}

export const setErrorData = (value) => (dispatch) => {
    dispatch({
        type: 'SET_ERROR_DATA',
        payload: value
    })
}

export const setActivationPeriod = (time) => (dispatch) => {
    let activationTime = 30;

    if (time > 0) {
        activationTime = time
    }

    dispatch({
        type: 'SET_ACTIVATION_PERIOD',
        payload: activationTime
    })
}

export const startTic = (time) => (dispatch) => {
    let timerId

    clearTimeout(timerId);
}

export const setBase64String = (data) => (dispatch) => {
    var temporaryFileReader = new FileReader();
    return new Promise((resolve, reject) => {
        temporaryFileReader.onerror = () => {
          temporaryFileReader.abort();
          reject(new DOMException("Problem parsing input file."));
        };

        temporaryFileReader.onload = () => {
            var arrayOfStrings = temporaryFileReader.result.split(";");
            var base64String = arrayOfStrings[1].split(",");

            resolve(base64String[1]);
        };
        temporaryFileReader.readAsDataURL(data);
    });
}